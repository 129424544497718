@font-face {
    font-family: 'Barlow';
    font-weight: 400;
    font-style: normal;
    src: local('Barlow'), url(./fonts/Barlow-Regular.woff) format('woff');
}
@font-face {
    font-family: 'Barlow';
    font-weight: 300;
    font-style: normal;
    src: local('Barlow'), url(./fonts/Barlow-Light.woff) format('woff');
}
@font-face {
    font-family: 'Barlow';
    font-weight: 500;
    font-style: normal;
    src: local('Barlow'), url(./fonts/Barlow-Medium.woff) format('woff');
}
@font-face {
    font-family: 'Barlow';
    font-weight: 400;
    font-style: italic;
    src: local('Barlow'), url(./fonts/Barlow-Italic.woff) format('woff');
}
@font-face {
    font-family: 'Barlow';
    font-weight: 300;
    font-style: italic;
    src: local('Barlow'), url(./fonts/Barlow-LightItalic.woff) format('woff');
}
@font-face {
    font-family: 'Barlow';
    font-weight: 500;
    font-style: italic;
    src: local('Barlow'), url(./fonts/Barlow-MediumItalic.woff) format('woff');
}
@font-face {
    font-family: 'Libre Barcode 128';
    font-weight: 400;
    src: local('Libre Barcode 128'), url(./fonts/LibreBarcode128-Regular.ttf) format('truetype');
}

:root {
    --background: #eeeeee;
    --device-orientation: landscape;
    --nav-background: #222527;
    --page-background: #151515;
    --nav-light: #3e4347;
    --glow: rgba(0, 0, 0, 0.5);
    --glow2: rgba(0, 0, 0, 0.5);
    --glow3: rgba(0, 0, 0, 0.5);
    --text0: white;
    --text1: #dddddd;
    --text2: #dddddd;
    --text3: #dddddd;
    --text4: white;
    --text10: black;
    --ui-green: #95C059;
    --ui-yellow: #E8BD31;
    --ui-grey0: #dddddd;
    --ui-grey1: #cccccc;
    --ui-grey2: #dddddd;
    --card: #303032;
    --card-outline: #151515;
    --timetable-light: #4c4c4f;
    --timetable-nonselected: #4c4c4f;
    --button-front: #333333;
    --button-back: #dddddd;
    --input-focus: #95c059;
    --link-colour: #61B0B5;
    --shadow-length-1-2: 2px;
}


.about__icon {
    margin-top: 40px;
    width: 150px;
}
.about__title {
    margin: 40px auto;
}
.about__button.button {
    margin: 0;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 6px var(--glow);
}
.about__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: fit-content;
    margin: auto;
}

body {
    font-family: Barlow, Roboto, "Fira Sans", "Segoe UI", Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: var(--text2);
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
}

div {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 5px;
}

.bigHeading {
    font-weight: 300;
    font-size: 30px;
    margin: 20px 5px;
}

h1 {
    font-weight: 300;
    font-size: 30px;
    text-align: center;
}
h1, h2 {
    font-weight: 500;
    font-size: 20px;
}
h3 {
    font-weight: 400;
    font-size: 18px;
}
h4 {
    font-size: 15px;
    font-weight: 500;
}
h5 {
    font-weight: 400;
    font-size: 15px;
}
h6 {
    font-weight: 400;
    font-size: 15px;
    color: var(--text3);
}
p {
    font-weight: 400;
    font-size: 13px;
}

a {
    color: var(--link-colour);
}

.page {
    background-color: var(--page-background);
    padding: 0;
    color: var(--text1);
    width: fit-content;

    overflow-x: auto;
    overflow-y: auto;
}


.page__prop {
    animation-duration: 0.2s;
    animation-name: pageFade;
    width: calc(100vw - 30px);
    min-width: 250px;
    max-width: 600px;
    padding: 15px;
    margin: 0 auto 20px auto;
}

@keyframes pageFade {
    from {
        opacity: 0;
    }

    /*50% {*/
    /*    opacity: 0.2;*/
    /*}*/

    to {
        opacity: 1;
    }
}

.app-container {
}

@media (max-aspect-ratio: 11/9) and (max-width: 699.9px) {

    .page {
        min-width: 100vw;
        min-height: calc(100vh - (100vw / 5.7887));
        height: calc(100vh - (100vw / 5.7887));
        max-height: calc(100vh - (100vw / 5.7887));
        margin-bottom: calc((100vw / 5.7887));
    }


    .page__prop {
        padding: 15px 15px 60px 15px;
    }


    .nav {
        padding-left: 15px;
        padding-right: 15px;
        position: fixed;
        bottom: 0;
        left: 0;

        box-shadow: 0 -2px 6px var(--glow2);

        width: calc(100vw - 30px);
        height: calc(100vw / 5.7887);

        padding-bottom: 1px;

        flex-direction: row;
    }


    .nav__button {
        height: calc(0.72 * (100vw / 5.7887));
        padding-top: calc(0.14 * (100vw / 5.7887));
        padding-bottom: calc(0.14 * (100vw / 5.7887));
        background-color: var(--nav-background);

        flex: 1 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nav__button__icon {
        width: 35px;
        height: 35px;
    }


    .nav__button__name {
        font-weight: 400;
        font-size: calc(5px + 2vw);
    }


    .nav__button--currentPage {
        background-image: linear-gradient(to top, var(--nav-light) 30%, var(--nav-background) 100%);
    }


    .period__center {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }


    .topBar__countdown.topBar__countdown--long {
        font-size: 30px;
    }


    .askLogin {
        width: 100vw;
        bottom: calc((100vw / 5.7887) + 20px);
    }

    body, p {
        font-weight: 400;
        font-size: 13px;
    }
}

@media (min-aspect-ratio: 11/9), (min-width: 700px) {

    .page {
        min-width: calc(100% - 70px);
        min-height: calc(100vh);
        height: 100vh;
        margin-left: 70px;
    }

    .page__prop {
        padding: 15px 15px 100px 15px;
    }


    .nav {
        width: 70px;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;

        box-shadow: 2px 0 6px var(--glow2);

        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .nav__button {
        padding-left: 10px;
        padding-right: 10px;
        width: 50px;

        flex: 0 1 70px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nav__button:hover {
        background-color: var(--nav-light);
    }

    .nav__button__icon {
        width: 30px;
        height: 30px;
    }


    .nav__button__name {
        font-weight: 400;
        font-size: 12px;
    }


    .nav__button--currentPage {
        background-image: linear-gradient(to right, var(--nav-light) 30%, var(--nav-background) 100%);
    }


    .period__center {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }


    .askLogin {
        width: calc(100vw - 70px);
        bottom: 40px;
    }

    .button:hover {
        color: var(--button-back);
        background-color: var(--button-front);
    }

    .toggle__side:hover {
        background-color: var(--ui-green);
        color: #333333;
    }

    body, p {
        font-weight: 400;
        font-size: 14px;
    }

    .savedBarcode:hover {
        border: solid 2px var(--text2);
    }
}


.nav {
    margin: 0;
    background-color: var(--nav-background);
    color: var(--text2);

    display: flex;
    align-items: center;
    justify-content: center;
}

.nav__button--center__icon {
    flex: 1 1 50px;
    width: 50px;
    height: 50px;

}


.page__bells {
    min-width: 200px;
}


.page__bells__row {
    margin-top: 10px;
    margin-bottom: 10px;
}


.period {
    background-color: var(--card);
    box-shadow: 0 var(--shadow-length-1-2) 4px var(--glow3);
    font-weight: 400;
    font-size: 20px;
    border-radius: 5px;
    padding: 0;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.period__top {
    padding: 5px;
    width: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.period__icon {
    border-radius: 5px;
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    /*vertically center the icon*/
    display: flex;
    justify-content: center;
    align-items: center;
}


.period__name {
    flex: 1 1;
    margin-left: 5px;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: -0.03em;
    color: var(--text0);
}

.period__room {
    border-radius: 5px;
    font-size: 25px;
    font-weight: 400;
    min-width: 45px;
    flex: 0 0;
    text-align: center;
}
.period__room--highlight {
    background-color: var(--ui-yellow);
    color: #000000;
}


.period__details {
    width: calc(100% - 10px);
    font-weight: 400;
    font-size: 13px;
    padding: 5px;
    border-top: solid 1px var(--card-outline);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    transition: all 0.2s;
}


.period__details.period__details--closed {
    height: 0;
    padding: 0;
    border: none;

    animation-duration: 0.2s;
    animation-name: disappear;
}


.period__details.period__details--expanded {
    height: 17px;

    animation-duration: 0.2s;
    animation-name: appear;
}


.period__details__item {
    padding: 0 5px;
}

@keyframes appear {
    from {
        color: transparent;
    }

    50% {
        color: transparent;
    }

    to {
        color: inherit;
    }
}

@keyframes disappear {
    from {
        color: inherit;
    }

    50% {
        color: transparent;
    }

    to {
        color: transparent;
    }
}

.break {
    font-weight: 400;
    font-size: 17px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 3.2vw;
    padding-right: 2.8vw;

}


.break__name {
    flex: 7 7;
}


.break__time {
    flex: 1 1;
    text-align: center;
}


.page__barcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.inputBox {
    padding: 4px;
    border-radius: 5px;
    font-family: inherit;
    border: solid 1px var(--ui-grey2);
    box-shadow: none;
    background-color: var(--card);
    color: var(--text2);
    outline: none;
}

.inputBox:focus {
    border: solid 2px var(--input-focus);
    padding: 3px;
}


.barcodeInput {
    padding: 5px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin: 10px;
}


.barcodeInput:focus {
    padding: 4px;
}


.button {
    background-color: var(--button-back);
    border-radius: 5px;
    color: var(--button-front);
    padding: 5px 10px;
    margin: 10px;
    font-size: 15px;
    font-family: inherit;
    border: solid 1px var(--button-back);
    width: fit-content;
}

.button:active {
    color: #333333;
    background-color: var(--ui-green);
}


.barcodeOutput {
    font-family: 'Libre Barcode 128', sans-serif;
    font-size: calc(50px + 10vw);
    font-weight: 400;
    padding: 20px 20px 0 20px;
    margin: 10px 0;
    background-color: white;
    color: black;
    white-space: pre;
}

.saveBarcodeBoxes {
    margin: 20px 0px;
    padding: 5px;
}

#saveBarcodeForm {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 15px;
}

#saveBarcodeForm__code {
    margin: 5px 0;
    width: 100%;
}

#saveBarcode__codeInput {
    font-size: 15px;
    height: 20px;
    width: 180px;
    flex: 1 1 180px;
}

#saveBarcode__input {
    font-size: 15px;
    height: 20px;
    width: calc(100% - 10px);
    flex: 1 1 180px;
}

#saveBarcode__submit {
    margin: 0 0 0 5px;
}

.savedBarcode__container {
    width: calc(100% - 10px);
}

.savedBarcode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: none !important;
    padding: 3px !important;
    border: solid 2px var(--card);
}

.savedBarcode.savedBarcode--selected {
    border: solid 2px var(--input-focus);
}

.savedBarcode__code {
    flex: 1 1;
    margin: 0 0 0 5px;
}

.savedBarcode__closeIcon {
    width: 20px;
    height: 20px;

}

.savedBarcode__closeIcon__wrapper {
    padding: 5px;
}

#topBar {
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
}

@media screen and (max-width: 310px) {
    #topBar {
        flex-direction: column;
    }
    .topBar__countdown {
        margin: 5px;
    }
}

@media screen and (min-width: 310px) {
    #topBar {
        flex-direction: row;
    }
}

.topBar__left {
    flex: 1 1 10px;
    /*-right: 5px;*/
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

.topBar__middle {
    flex: 0 0;
    text-align: center;
    min-width: 100px;
}

.topBar__right {
    flex: 1 1 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    text-align: right;
}

.topBar__countdown {
    font-size: 40px;
    font-weight: 400;
}

.topBar__until {
    color: #888888;
    text-align: right;
}

.topBar__right__icon {
    margin-left: 5px;
}

.topBar__side {}
.topBar__dateDisplay {}
.topBar__sideIcons {}


.askLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: fixed;

}

.askLogin__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
    box-shadow: 0 2px 4px var(--glow);
}

.askLogin__icon {
    width: 20px;
    height: 20px;
    flex: 0 1 20px;
    padding-right: 5px;
}


.page__settings {
    display: block;
    min-width: 250px;
}

.settings {
    margin: 5px;
}

.settings__classInfo {
    display: block;
    margin: 0;
    padding: 0;

}

.settings__classInfo__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

}

.settings__classInfo__col {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    flex: 0 0 26px;

    /*some css set by js*/
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1px;
    border: solid 1px #d0d0d0;
}

.settings__classInfo__col--selected {
    padding: 0px;
    border: solid 2px var(--text4);
}

.settings__classInfo__name {
    margin: 0 3px;
    min-width: 100px;
    flex: 2 2 200px;
    font-size: 15px;
    font-weight: 400;
}

.settings__classInfo__code {
    margin: 0 3px;
    min-width: 50px;
    flex: 1 1 50px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.settings__classInfo__icon {
    color: inherit;
    width: 15px;
    height: 15px;
}

.settings__example__toggle {
    margin: 5px;
}

.group {
    background-color: var(--card);
    color: var(--text2);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.colPicker {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 3px;

}


.colPicker__option {
    margin: 2px;
}

#banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
}


.page__timetable {
    min-width: 285px;
}

@media screen and (max-width: 665px) {

    .timetable__week {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 285px;
        margin: 0 auto;
    }


    .tt__wk__wrapper {
        background-color: var(--card);
        box-shadow: 0 1px 4px var(--glow3);
        border-radius: 5px;
        padding: 5px;
        flex: 0 0;

        width: 275px;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;

    }


    .tt__wk__heading {
        padding: 5px;
    }


    .tt__indexCol {
        flex: 0 0 25px;
        padding-top: 25px;
    }


    .tt__index {
        padding: 15px 2.5px;
    }


    .tt__index__icon {
        width: 20px;
        height: 20px;
        background-color: var(--timetable-light);
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
    }


    .tt__day {
        flex: 0 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

    }


    .tt__emptyCell {
        margin: 5px;
        height: 40px;
        width: 40px;

        border-radius: 10px;
        background-color: var(--timetable-light);
    }


    .tt__cell {
        background-color: var(--card);
        margin: 5px;
        height: 40px;
        width: 40px;
        box-shadow: 0 1px 4px var(--glow3);
        border-radius: 10px;
        font-size: 13px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .tt__cell__name {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        min-height: 23px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-size: 12px;
        font-weight: 400;
    }

    .tt__cell__name--3char {
        font-size: 13px;
    }

    .tt__cell__name--4char {
        font-size: 13px;
    }

    .tt__cell__name--5char {
        font-size: 12px;
    }

    .tt__cell__name--6char {
        font-size: 11px;
        font-weight: 500;
    }


    .tt__cell__room {
        min-height: 17px;
        width: 40px;
        border-radius: 10px;
        background-color: var(--card);
        color: var(--text1);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-size: 13px;
        font-weight: 500;
    }

    .tt__cell__room--3char {
        font-size: 13px;
    }

    .tt__cell__room--4char {
        font-size: 13px;
    }

    .tt__cell__room--5char {
        font-size: 12px;
    }

    .tt__cell__room--6char {
        font-size: 11px;
    }


    .tt__dayName {
        width: 40px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 2.5px 5px;
        border-radius: 10px;
        font-size: 14px;

    }
}

@media screen and (min-width: 665px) {

    .timetable__week {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 565px;
        margin: 0 auto;
    }


    .tt__wk__wrapper {
        background-color: var(--card);
        box-shadow: 0 1px 4px var(--glow3);
        border-radius: 5px;
        padding: 5px;
        flex: 0 0;

        width: 555px;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;

    }


    .tt__wk__heading {
        padding: 5px;
    }


    .tt__indexCol {
        flex: 0 0 30px;
        padding-top: 28px;
    }


    .tt__index {
        padding: 7.5px 2.5px;
    }


    .tt__index__icon {
        width: 25px;
        height: 25px;
        background-color: var(--timetable-light);
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
    }


    .tt__day {
        flex: 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

    }


    .tt__emptyCell {
        margin: 5px;
        height: 30px;
        width: 95px;

        border-radius: 10px;
        background-color: var(--timetable-light);
    }


    .tt__cell {
        background-color:var(--card);
        margin: 5px;
        height: 30px;
        width: 95px;
        box-shadow: 0 1px 4px var(--glow3);
        border-radius: 10px;
        font-size: 17px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }


    .tt__cell__name {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        height: 30px;
        width: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-size: 15px;
        font-weight: 400;
    }

    .tt__cell__name--3char {
        font-size: 16px;
    }

    .tt__cell__name--4char {
        font-size: 16px;
    }

    .tt__cell__name--5char {
        font-size: 15px;
    }

    .tt__cell__name--6char {
        font-size: 14px;
        font-weight: 500;
    }

    .tt__cell__room {
        height: 30px;
        width: 45px;
        border-radius: 10px;
        background-color: var(--card);
        color: var(--text1);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;

        font-size: 17px;
        font-weight: 500;
    }

    .tt__cell__room--3char {
        font-size: 17px;
    }

    .tt__cell__room--4char {
        font-size: 16px;
    }

    .tt__cell__room--5char {
        font-size: 14px;
    }

    .tt__cell__room--6char {
        font-size: 12px;
    }


    .tt__dayName {
        width: 60px;
        height: 23px;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 2.5px 5px;
        border-radius: 10px;

    }
}

.tt__dayName {
    background-color: var(--timetable-light);
    color: var(--text2);
}

.tt__dayName--today {
    background-color: var(--ui-grey0);
    color: var(--text10);
}

.tt__cell__name--notSelected {
    background-color: var(--timetable-nonselected);
}

.feeds__container {
    width: 100%;
    max-width: 600px;
}

.feedItem {
    background-color: var(--card);
    color: var(--text2);
    box-shadow: 0 1px 4px var(--glow3);
    border-radius: 5px;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto;
}

.feedItem__title {
    padding: 5px 10px 2px 10px;
    margin: 0;
}

.feedItem__metadataRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-bottom: solid 1px var(--card-outline);
    margin: 0;
    padding: 0 5px 2px 5px;
}

.feedItem__metadata {
    padding: 0 5px 3px 5px;
    margin: 0;
    font-size: 15px;
    /*font-weight: 400;*/
}

.feedItem__body {
    margin: 5px 10px 4px 10px;
}

.feedItem__body > p,
.feedItem__body > h1,
.feedItem__body > h2,
.feedItem__body > h3,
.feedItem__body > h4,
.feedItem__body > h5,
.feedItem__body > h6,
.feedItem__body > div,
.feedItem__body > br {
    margin: 0 0 5px 0;
}

.feedItem__body--minimised {
    height: 18px;
    overflow: hidden;
}

.feedItem__body--expanded {
    margin-bottom: 10px;
}

.feedItem__meetingTag {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1px;
    margin: 0 5px 2px 5px;
    /*this text need to be always black*/
    color: black;
}

.feedItem__meetingTag--silver {
    background-color: var(--ui-grey1);
}
.feedItem__meetingTag--gold {
    background-color: var(--ui-yellow);
}

.feedItem__metadata__meeting {
    padding: 0 5px;
}

.change-feed-year {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.toggle {
    background-color: transparent;
    border-radius: 5px;
    color: var(--ui-grey0);
    border: none;
    font-size: 15px;
    font-family: inherit;
    margin: 10px 5px;
    width: fit-content;
}

.toggle__side {
    padding: 5px 10px;
    display: inline-block;
    border: solid 1px var(--ui-grey0);
}

.toggle__left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
}

.toggle__right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
}

.toggle--selected {
    background-color: var(--ui-green);
    color: #333333;
}

.dropdown {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}


.dropdown__label {
    padding-bottom: 3px;
    margin-right: 20px;
}

.dropdown__selector {
    font-family: inherit;
    font-size: 15px;
    font-weight: 400;
    border-radius: 5px;

    border: solid 1px #333333;
    padding: 1px;
    background-color: #eeeeee;
    color: black;
}

.dropdown__selector:focus {
    border: solid 2px var(--ui-green);
    padding: 0;
}

.no_fold {
    white-space: pre-wrap;
}

b, strong {
    font-weight: 500;
}

.noConnectionIcon {
    width: 25px;
    height: 25px;
    flex: 0 0 25px;
    padding: 0 5px;
}

.loadingIcon {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    padding: 0 5px;

}

.askLogin__icon {
    width: 20px;
    height: 20px;
    flex: 0 1 20px;
    padding-right: 5px;
}

.noConnection {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.loadingGroup {
    min-height: 25px;
}

.darkBanner {
    color: #ffffff;
    background-color: #333333;
}

.barcodeFontLoader {
    font-size: 3px;
    opacity: 0;
}

.barcodeFontLoader-0 {
    font-family: 'Libre Barcode 128', sans-serif;
}

.barcodeFontLoader-1 {
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
}

.barcodeFontLoader-2 {
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
}

.barcodeFontLoader-3 {
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
}

.barcodeResize__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.barcodeResize__button {
    margin: 10px;
    width: 25px;
    height: 25px;
    color: var(--ui-grey0);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
}

.barcodeResize__button:active {
    color: var(--ui-green);
}

.barcodeResize__icon {
    min-width: 25px;
    min-height: 25px;
}

.theme_switch_banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.small__button {
    margin: 0 0 0 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swap-theme-icon {
    color: var(--text2);
}

.swap-theme-text {
    padding-bottom: 1px;
}

